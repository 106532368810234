@import '../../_variables';

.auth {
    flex: 1;
    display: flex;
    position: relative;
    min-height: 100vh;

    .main-content-view {
        padding: 20px;
    }

    .main-auth-scrollbar {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .footer {
        margin-right: 0;
        margin-left: 0;
    }
}

.auth-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .auth-card {
        max-width: 360px;
        min-height: 350px;
        width: 100%;
        overflow: hidden;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
        position: relative;
        border: 0 none;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        color: #111827;
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.35;
        list-style: none;
        font-feature-settings: "tnum", "tnum";
        position: relative;
        background: #fff;
        border-radius: 16px !important;

        @media screen and (min-width: $screen-sm) {
            min-height: 450px;
        }

        & .ant-card-body {
            padding: 0;
            display: flex;
            flex: 1;
        }
    }

    .header {
        .rbc-logo {
            float: left;
            width: 30px;
            margin: 7px 0px 0px 15px;
        }

        background-color: white !important;
    }

    .auth-card-header {
        margin-top: 25px;
        margin-bottom: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 37px;

        @media screen and (min-width: $screen-md) {
            margin-bottom: 36px;
            margin-left: 37px;
        }

        .rbc-logo {
            width: 30px;
            margin: 0px;
        }

        .img1 {
            margin-right: 15px;
        }

        .img2-parent {
            width: 80px;
            height: 50px;
            object-fit: contain;
        }

        .img2 {
            margin-left: 15px;
            width: auto;
            max-width: 80px;
            object-fit: contain;
            height: 50px;
        }

        .vl {
            border-left: 1px solid black;
            height: 28px;
        }

        & img {
            cursor: pointer;
            height: auto;
            margin-right: 10px;

            [dir=rtl] & {
                margin-right: 0;
                margin-left: 10px;
            }
        }
    }

    .auth-logo-name {
        font-weight: $font-weight-medium;
        font-size: 24px;
    }

    .auth-main-content {
        padding: 24px;
        display: flex;
        flex-direction: column;
        width: 100%;

        @media screen and (min-width: $screen-sm) {
            padding: 20px;
            width: 100%;
        }

        @media screen and (min-width: $screen-lg) {
            width: 100%;
            padding: 40px;
        }
    }

    .auth-wel-action {
        position: relative;
        padding: 24px;
        display: none;
        background-color: $grey-900;
        color: $white;
        font-size: $font-size-base;
        width: 100%;

        @media screen and (min-width: $screen-sm) {
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 50%;
        }

        @media screen and (min-width: $screen-lg) {
            width: 60%;
            padding: 40px;
        }

        & h2 {
            color: $white;
            font-size: 30px;
            font-weight: $font-weight-bold;
        }

        & p {
            line-height: 1.6;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .auth-wel-content {
        max-width: 320px;
    }

    .auth-recon-content {
        display: flex;
        flex-direction: column;
        flex: 1;

        & .ant-card-body {
            padding-top: 10px;

            @media screen and (min-width: $screen-sm) {
                padding-top: 28px;
            }

            @media screen and (min-width: $screen-xxl) {
                padding-top: 40px;
            }
        }
    }

    .ant-input-affix-wrapper.ant-input-password {
        padding: 0px 10px 0px 0px !important;
    }

    .ant-input {
        padding: 6px 11px !important;
    }

}

.confirm-content {
    margin-bottom: 20px;
    font-size: $font-size-base;

    @media screen and (min-width: $screen-xxl) {
        font-size: $font-size-lg;
        margin-bottom: 40px;
    }
}

.confirm-code-input {
    margin-bottom: 24px;

    @media screen and (min-width: $screen-xxl) {
        margin-bottom: 40px;
    }

    & .react-code-input input {
        &:focus {
            outline: none;
        }
    }
}

.confirm-btn {
    border-radius: $border-radius-base;
    width: 100%;
    font-weight: $font-weight-regular;
    font-size: $font-size-base;
}


.reset-form {
    position: relative;

    & .form-field {
        margin-bottom: 20px;

        & p {
            margin-bottom: 24px;
            font-size: $font-size-base;

            @media screen and (min-width: $screen-xxl) {
                font-size: $font-size-lg;
            }
        }
    }

    & .ant-form-item-explain-error {
        text-align: left;

        [dir=rtl] & {
            text-align: right;
        }
    }

    & .react-code-input input {
        &:focus {
            outline: none;
        }
    }
}

.login-page-title {
    text-align: center;
    margin-bottom: 15px;
    font-size: 25px;
    font-weight: 700;
}

.sign {
    flex: 1;
    display: flex;
    flex-direction: column;

    .sign-content {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .sign-footer {
        background-color: $body-background;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px 24px;
        margin: 12px -24px -24px;

        @media screen and (min-width: $screen-sm) {
            padding-left: 20px;
            padding-right: 20px;
            margin-left: -20px;
            margin-right: -20px;
            margin-bottom: -20px;
        }

        @media screen and (min-width: $screen-lg) {
            padding-left: 40px;
            padding-right: 40px;
            margin-left: -40px;
            margin-right: -40px;
            margin-bottom: -40px;
        }

        & .signup-btn {
            text-transform: capitalize;
        }
    }

    .sign-text {
        font-size: $font-size-base;
        margin-right: 10px;

        [dir=rtl] & {
            margin-right: 0;
            margin-left: 10px;
        }

        @media screen and (min-width: $screen-md) {
            margin-right: 16px;

            [dir=rtl] & {
                margin-right: 0;
                margin-left: 16px;
            }
        }
    }

    .sign-text-grey {
        color: $text-color-disabled;
    }

    .sign-socialLink {
        display: flex;
        align-items: center;
    }

    .sign-link {
        color: $primary-color;
        font-size: $font-size-base;
        margin-bottom: 16px;
        text-align: right;
        display: block;

        span {
            cursor: pointer;
        }

        [dir=rtl] & {
            text-align: left;
        }
    }

    .ant-btn-primary:focus,
    .ant-btn-primary:hover {
        color: #fff !important;
        background: #31abe8 !important;
        border-color: #31abe8 !important;
    }

    .ant-btn.ant-btn-primary.sign-btn {
        border-radius: $border-radius-base;
        width: 100%;
        font-weight: $font-weight-regular !important;
        font-size: $font-size-base !important;
        text-transform: uppercase !important;
        line-height: 1;
        color: #fff !important;
        background: #0A8FDC !important;
        border-color: #0A8FDC !important;
    }

    .sign-btn-full {
        width: 100%;
    }

    .sign-icon-btn {
        color: $text-color-secondary;
        padding: 6px 4px 4px;
        border: 0 none;
        box-shadow: none;
        background-color: transparent;
        width: 26px;
        min-width: 26px;
        height: 26px;

        @media screen and (min-width: $screen-md) {
            width: 36px;
            min-width: 36px;
            height: 36px;
            padding: 8px 6px 6px;
        }

        &:hover,
        &:focus {
            color: $primary-color;
            background-color: transparent;
        }

        & .anticon {
            font-size: $font-size-base;

            @media screen and (min-width: $screen-md) {
                font-size: $font-size-lg;
            }

            & svg {
                display: block;
            }
        }

        &.ant-btn-icon-only>* {
            font-size: $font-size-base;

            @media screen and (min-width: $screen-md) {
                font-size: $font-size-lg;
            }
        }
    }

    .sign-form {
        flex: 1;
        display: flex;
        flex-direction: column;

        & .form-field {
            margin-bottom: 20px;
        }

        & .form-btn-field {
            position: relative;
            margin-bottom: 16px;
        }

        & .form-field-action {
            font-size: $font-size-base;
            color: $text-color-secondary;
            margin-top: auto;

            & span {
                margin-right: 8px;
                display: inline-block;

                [dir=rtl] & {
                    margin-right: 0;
                    margin-left: 8px;
                }
            }
        }
    }

    .underlineNone {
        text-decoration: none;
    }

    .colorTextPrimary {
        color: $primary-color;
    }

    .rememberMe {
        position: relative;

        & label {
            margin-bottom: 10px;
        }
    }
}
