@import "~antd/dist/antd.min.css";
@import '../src/styles/comon.scss';
@import '../src/_variables';

body {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

$inputSelectBG: red;

* {
    box-sizing: border-box;
}

.ant-layout-has-sider {
    min-height: calc(100vh - 64px);
}

.ant-layout-header {
    padding: 0px !important;
    background: #006ac3 !important;
}
.action-dropdown{
    .ant-popover-content{
        width: 150px;
    }
}

.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu.ant-menu-dark .ant-menu-sub {
    background: transparent !important;
    // font-weight: bold;
}

.logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: #006ac3 !important;
}
.rbc-logo {
    float: left;
    width: 30px;
    margin: 7px 0px 0px 15px;
}
.ottawa-logo{
    height: 40px;
    width: 90px;
    object-fit: contain;
    margin: 0px 0px 0px 15px;
}

.right.d-flex{
    margin-right: 20px;
}
.header{
    border-bottom: 3px solid #006ac3;
    background-color: white !important;
    .right {
        float: right;
        width: 475px;
    
        .header-menu {
            margin-bottom: 0px;
            padding: 0px 15px;
            font-weight: 600;
            color: black;
            text-transform: uppercase;
            cursor: pointer;
           
        }
    
        .ant-menu-overflow-item.ant-menu-item {
            font-weight: 600;
            color: white;
        }
    
        // margin: 16px 0 16px 24px;
    }
}


.d-flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.site-layout-background {
    background: #fff;
    .ant-menu-inline .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-vertical .ant-menu-item:after{
        border-right: 3px solid #006ac3;
    }
}

.error-class {
    .ant-message-error .anticon {
        color: white !important;
    }

    .ant-message-notice-content {
        background: red;
        color: white;
        font-weight: bold;
    }
}

.padding-around {
    padding: 20px 0px;

    .red {}

    .green {
        text-align: right;
    }
}

.tab {
    background-color: #f0f2f5;
    margin-bottom: 5px;
    color: rgb(0 0 0 / 50%);
    padding: 20px 10px;
    width: 100%;
    display: flex;
    overflow: auto;

    .b-right {
        border-right: 3px solid #a3a6aa;
    }

    .active {
        color: rgb(0 0 0 / 100%);
    }

    p {
        padding-right: 25px;
        padding-left: 25px;
        margin-bottom: 0px;
        font-weight: 600;

        span {
            cursor: pointer;
        }
    }

    @media screen and (min-width: $screen-xxl) {
        font-size: 20px;
    }
}

.report-view {
    .nav-add-btn {
        justify-content: end;
    }

    .nav-sub-header {
        margin-bottom: 2rem;
    }

    .filter-report.ant-btn-primary {
        border-color: #585754;
        background: #585754;
        background-color: #585754;

        &.active {
            border-color: #006ac3;
            background: #006ac3;
            background-color: #006ac3;
        }
    }

    // .ant-input-search-button.ant-btn-primary {
    //     background-color: transparent;
    //     color: black;
    //     border-color: #d9d9d9;

    //     &:hover {
    //         border-color: #40a9ff;
    //     }
    // }

    .filter-report {
        margin-left: 10px;
    }

    .filter-section {
        padding: 25px 20px;
        border-radius: 10px;
        background-color: #f6f6f6;
        margin-bottom: 20px;

        .ant-form-item {
            margin-bottom: 0px;
        }

        .ant-form-item-label {
            label {
                font-weight: 500;
            }
        }
    }

    .switch-view {
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .gird-item {
        .gird-box {
            background-color: #f6f6f6;
            border-radius: 10px;
            padding: 15px 20px;

            .group-name {
                font-weight: 600;
            }

            .group-description {
                text-align: justify;

            }

            .grid-report-box {
                padding: 10px 20px;
            }

            .report-list {
                background-color: #848380;
                padding: 15px;
                color: white;
                border-radius: 10px;
                margin-bottom: 15px;

                p {
                    margin-bottom: 0px;
                }
            }
        }
        margin-bottom: 20px;
    }
    .ant-table-wrapper{
        padding: 0px 35px;
    }
    .ant-badge-multiple-words{
        padding: 0px 2px;
    }
    
}
//576px;
@media screen and (max-width: $screen-sm) { 
    .content-layout {
        padding: 0px 10px 24px !important;
    }

    .ant-layout-content.site-layout-background.content {
        padding: 5px 10px !important;
    }

    .ant-layout-header {
        flex: 0 0;
    }

    .ant-layout-sider-collapsed.ant-layout-sider {
        flex: 0 0 60px !important;
        max-width: 60px !important;
        min-width: 60px !important;
        width: 60px !important;

        .ant-layout-sider-trigger {
            width: 60px !important;
        }
    }

    .nav-add-btn {
        margin-bottom: 10px;
    }

    .report-mngmnt-view {
        .nav-add-btn {
            flex-flow: wrap;

            div {
                margin-right: 10px;
            }

            .ml15 {
                margin-left: 0px;

            }
        }
    }
}

// $screen-md: 768px;
@media screen and (max-width: $screen-md) { 
    .nav-add-btn {
        margin-bottom: 10px;
    }
    .report-view{
        .report-container{
            column-count: 1;
        }
        .ant-input-search-with-button{
            width: 70% !important;
        }
    } 
    .report-mngmnt-view {
        .nav-add-btn {
            flex-flow: wrap;

            div {
                margin-right: 10px;
            }

        }
    }
}

// $screen-lg: 992px;
@media screen and (max-width: $screen-lg) {
    .nav-add-btn {
        margin-bottom: 10px;
    }

    .report-mngmnt-view {
        .nav-add-btn {
            flex-flow: wrap;

            div {
                margin-right: 10px;
            }

            .ml15 {
                margin-left: 0px;

            }
        }
    }
}

// $screen-xxl: 1600px;
