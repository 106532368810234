
.nav-sub-header {
    .nav-add-btn {
        display: flex;
        align-items: center;
        align-content: center;

        &.custom {
            margin-right: 30px;
            justify-content: space-between;
        }

        .nav-add-label {
            margin-left: 10px;
            cursor: pointer;
        }
    }

    .ant-input {
        height: 25px !important;

    }

    .ant-input-search-button {
        height: 35px !important;
    }
}

.export-popup-main {
    div {
        padding-top: 10px;
        padding-bottom: 10px;

        .ant-btn {
            margin-right: 5px;
            cursor: pointer;
        }

        label {
            cursor: pointer;
        }
    }
}

.listing-table {
    margin-top: 20px;
}

.input-class {
    .ant-input {
        height: 40px;
        border-radius: 10px;
    }

    .ant-col.ant-form-item-label {
        label {
            font-weight: 600;
        }
    }

    .ant-input-disabled {
        color: black;
    }
}

.setting-imgage{
    .ant-upload-list-item {
        width: 350px;
    }    
}

.ant-btn-primary {
    background-color: #006ac3;
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
    border-color: #40a9ff;
    background: #40a9ff;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
    color: rgb(255 255 255);
    background-color: rgba(0, 106, 195, 0.8);
}

.cursor {
    cursor: pointer;
}

.archive-card {
    background-color: #eee;
    border-radius: 10px;
    margin-bottom: 2rem;

    .ant-card-body {
        padding: 20px;
        border-radius: 10px;
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            margin-bottom: 0px;
        }

        .switch-delete {
            display: flex;
            align-items: center;
            align-content: center;

            .ant-btn {
                height: 35px;
            }
        }

        .ant-card {
            font-size: 16px;
        }

        .ant-switch {
            min-width: 44px;
            height: 35px;
            line-height: 35px;
            padding: 0 5px;
            background-image: none;
            background-color: #5f6062;

            .ant-switch-inner {
                font-size: 14px;
            }

            .ant-switch-handle {
                top: 11px;
                left: 11px;
            }
        }

        .ant-switch-checked {
            .ant-switch-handle {
                left: calc(100% - 25px) !important;
            }
        }

        .ant-switch-checked {
            background-color: #006ac3;
        }

    }

    &.survey {
        .content {
            display: block;

            .update-title {
                margin-bottom: 10px;
            }
        }
    }
}

.error-img {
    max-width: 300px;

    svg {
        fill: #006ac3;
    }
}

.error-content {
    text-align: center;
}

.main-title {
    color: black;
    font-weight: bold;
    font-size: 16px;
}

.ant-layout-sider-trigger {
    background: #006ac3 !important;
}

th.ant-table-cell {
    background: #f0f2f5 !important;
    border-bottom: 1px solid #f0f2f5 !important;
}

.ant-table-cell {
    .ant-tag {
        margin-top: 2px;
    }
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 40px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 10px;
    min-height: 40px;
    padding: 5px;
}

.field-password .ant-input-affix-wrapper {
    padding: 0px 10px 0px 10px !important;
    border-radius: 10px;
}

.export-btn {
    .anticon.anticon-download {
        font-size: 18px;
    }
}
.import{
    margin-left: 15px;
}
.error-msg{
    p{
        margin-bottom: 5px;
        color:red;
    }
}
.disabled-edit {
    cursor: not-allowed;
}

.cursor.disabled {
    pointer-events: none;
}

.fix-version {
    background: #006ac3;
    border: 1px solid gray;
    border-radius: 5px;
    font-weight: 700;
    padding: 0px;
    position: fixed;
    right: 0;
    text-align: center;
    bottom: 0;
    font-size: 14px;
    width: 100px;
    color: white;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
}

.report-user-select {
    .ant-switch-inner {
        font-size: 14pxta;
    }

    span.ant-form-text {
        display: inline-block;
        width: 330px;
    }

    .ant-switch {
        min-width: 44px;
        height: 35px;
        line-height: 35px;
        padding: 0 5px;
        background-color: #5f6062;
        background-image: none;

        .ant-switch-inner {
            font-size: 14px;
        }

        .ant-switch-handle {
            top: 11px;
            left: 11px;
        }
    }

    .ant-btn-dangerous.ant-btn-primary {
        height: 35px;
    }

    .ant-switch-checked {
        background-color: #006ac3;
    }


    .ant-switch-handle {
        top: 6px;
        left: 6px;
    }

    .ant-switch-checked .ant-switch-handle {
        left: calc(100% - 23px);
    }
}

.role-switch {
    background: #eee;
    padding: 20px 20px 1px 20px;
    border-radius: 10px;

    .ant-form-item-control-input-content {
        display: flex;
        justify-content: space-between;
    }

    .ant-row.ant-form-item {
        // margin: 0 0 20px;
    }

    &.stream {
        .count-title {
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            font-weight: 500;
        }

        .stream-btn {
            border-color: #5f6062;
            background: #5f6062;
            height: 35px;
            padding: 2.4px 20px;
        }

        .ant-upload-list-item-card-actions-btn {
            display: none;
        }
    }

    &.survey {
        padding: 20px;
    }
}

.stream-add {
    .no-of-text {
        width: 100%;
        height: 40px;
        border-radius: 10px;
        line-height: 1.5715;
        padding: 4px 0px;

        .ant-input-number-handler-wrap {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
        }
    }

    .delete-stream {
        width: 100%;
        text-align: right;
        margin-right: 25px;
        display: flex;
        justify-content: end;

        svg {
            font-size: 20px;
            cursor: pointer;
            color: red;
        }
    }
}

.change-view {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.new-fund-datepicker {

    .ant-picker-header-super-prev-btn,
    .ant-picker-header-super-next-btn {
        display: none !important;
    }
}

.setting-view {
    display: flex;
    margin-bottom: 5px;
}

.email-setting {
    .text {
        display: flex;
        align-items: center;

        p {
            margin-bottom: 0px;
        }
    }

    .input-button {
        display: flex;
        align-items: center;
    }
}

.survey-add {
    .title {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-weight: bold;
    }

    .gird-box-survey {
        margin: 0;
        display: grid;
        grid-template-rows: 1fr auto;
        margin-bottom: 25px;
        break-inside: avoid;
    }

    .gird-box-survey {
        border-radius: 10px;

        .group-name {
            font-weight: 600;
        }

        .group-description {
            text-align: justify;

        }

        .grid-report-box {
            column-gap: 20px;
            column-count: 2;
        }

        .report-list {
            background-color: #848380;
            // padding: 15px 0px;
            color: white;
            border-radius: 10px;
            margin-bottom: 15px;
            position: relative;

            display: grid;
            grid-template-rows: 1fr auto;
            page-break-inside: avoid;
            break-inside: avoid;

            .report-title {
                padding: 0px 15px;
            }

            .report-title {
                padding: 0px 15px;
                padding-top: 15px;
            }

            .report-description {
                padding: 0px 15px;
                padding-bottom: 15px;
            }

            &.active {
                // background-color: #006ac3;
                // color:white;
                // background-color: #0d526e;
                color: rgba(255, 255, 255, 0.10);
                background-color: #006ac3;
                -webkit-transition: background-color 800ms linear;
                -ms-transition: background-color 800ms linear;
                transition: background-color 800ms linear;
                // color: #006ac3;
            }

            .report-title {
                font-weight: 600;
                text-align: center;
            }

            .report-description {
                font-weight: 300;
                text-align: justify;
            }

            .overlay {
                display: none;
                text-align: center;
            }

            &.active .overlay {
                display: block;
                position: absolute;
                transition: .1s ease;
                width: 100%;
                max-width: 100%;
                z-index: 999;
                font-weight: bold;
                color: white;
                text-align: center;
                height: 100%;
                align-items: center;
                justify-content: center;
                display: flex;
                text-transform: uppercase;

                span {
                    font-size: 16px !important;
                }
            }

            p {
                margin-bottom: 0px;
            }


            cursor: pointer;

            .text {
                color: white;
                font-size: 20px;
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                text-align: center;
            }
        }

    }

    .program {
        padding: 50px 0px 50px 50px;

        .ant-form-item-label>label {
            font-weight: bold;
        }

        .role-switch.survey {
            padding: 0px;
        }

        .gird-box-inner {
            padding: 18px 18px 0px 18px;
        }

        .grid-repor-right {
            padding-left: 20px;

            .report-title {
                margin-bottom: 0px !important;
            }

            .section {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 18px;
            }
        }
    }

}

.role-switch-homepage {
    background: #eee;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ant-row.ant-form-item {
        margin: 0 0 0px;
    }

    .ant-form-item-control-input-content {}
}

.text-center {
    text-align: center;
}

.center {
    .ant-form-item-control-input-content {
        justify-content: center !important;
    }
}

.date-picker {
    .ant-picker {
        height: 40px;
        border-radius: 10px;
    }
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
    height: 28px;
    line-height: 28px;
    font-size: 14px;
    background-color: #eee;
    margin-right: 10px;
    border-radius: 5px;
}

.right {
    text-align: right;
}




$sizes: 120;

@mixin margin-classes {
    @for $i from 1 through $sizes {
        $margin: $i * 0.25rem;

        /* margin #{$margin} */
        .m#{$i} {
            margin: $margin;
        }

        .ml#{$i} {
            margin-left: $margin;
        }

        .mr#{$i} {
            margin-right: $margin;
        }

        .mt#{$i} {
            margin-top: $margin;
        }

        .mb#{$i} {
            margin-bottom: $margin;
        }

        .mx#{$i} {
            margin-left: $margin;
            margin-right: $margin;
        }

        .my#{$i} {
            margin-top: $margin;
            margin-bottom: $margin;
        }
    }
}

@include margin-classes;


@mixin padding-classes {
    @for $i from 1 through $sizes {
        $padding: $i * 0.25rem;

        /* padding #{$padding} */
        .p#{$i} {
            padding: $padding;
        }

        .pl#{$i} {
            padding-left: $padding;
        }

        .pr#{$i} {
            padding-right: $padding;
        }

        .pt#{$i} {
            padding-top: $padding;
        }

        .pb#{$i} {
            padding-bottom: $padding;
        }

        .px#{$i} {
            padding-left: $padding;
            padding-right: $padding;
        }

        .py#{$i} {
            padding-top: $padding;
            padding-bottom: $padding;
        }
    }
}

@include padding-classes;


/* Scroll bar style*/

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    min-width: 70px;
    min-height: 70px;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.13);
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.23);
}

::-webkit-scrollbar-track {
    background: transparent;
}


.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    border-radius: 0 10px 10px 0;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 10px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 11px !important;
}

.report-view {

    .report-container {
        column-count: 2;
        column-gap: 20px;
    }

    img {
        max-width: 100%;
        display: block;
    }

    .gird-box {
        margin: 0;
        display: grid;
        grid-template-rows: 1fr auto;
        margin-bottom: 25px;
        break-inside: avoid;
    }

    .gird-box {
        background-color: #f6f6f6;
        border-radius: 10px;
        padding: 15px 20px;

        .group-name {
            font-weight: 600;
        }

        .group-description {
            text-align: justify;

        }

        .grid-report-box {
            padding: 10px 20px;
        }

        .report-list {
            background-color: #848380;
            // padding: 15px 0px;
            color: white;
            border-radius: 10px;
            margin-bottom: 15px;
            position: relative;

            .report-title {
                padding: 0px 15px;
            }

            .report-title {
                padding: 0px 15px;
                padding-top: 15px;
            }

            .report-description {
                padding: 0px 15px;
                padding-bottom: 15px;
            }

            &.active {
                // background-color: #006ac3;
                -webkit-transition: background-color 800ms linear;
                -ms-transition: background-color 800ms linear;
                transition: background-color 800ms linear;
                // color: #006ac3;
                // color: rgba(255, 255, 255, 0.15);
                // background-color: #0d526e;
                color: rgba(255, 255, 255, 0.10);
                background-color: #006ac3;
            }

            .report-title {
                font-weight: 600;
                text-align: center;
            }

            .report-description {
                font-weight: 300;
                text-align: justify;
            }

            .overlay {
                display: none;
                text-align: center;
            }

            &.active .overlay {
                display: block;
                position: absolute;
                transition: .1s ease;
                width: 100%;
                max-width: 100%;
                z-index: 999;
                font-weight: bold;
                color: white;
                text-align: center;
                height: 100%;
                align-items: center;
                justify-content: center;
                display: flex;
                text-transform: uppercase;

                span {
                    font-size: 16px !important;
                }
            }

            p {
                margin-bottom: 0px;
            }


            cursor: pointer;

            .text {
                color: white;
                font-size: 20px;
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                text-align: center;
            }
        }

    }

    .gird-box>.gird-box-inner {
        grid-row: 1 / -1;
        grid-column: 1;
    }

    .gird-box a {
        color: black;
        text-decoration: none;
    }

    .open-report {
        background-color: red;

        &:hover {
            background-color: gray;
        }
    }

}

.pass-btn {
    .border {
        border-radius: 40px;
    }
}

.password-popup-main {
    padding-top: 10px;
    padding-bottom: 5px;

    p {
        margin-bottom: 0px !important;
    }

    .red {
        color: red;
    }

    .green {
        color: green;
    }

    .gray {
        color: rgba(0, 0, 0, 0.75);
    }
}

.ant-switch-handle {
    width: 12px;
    height: 12px;
}

.logout-modal,
.delete-modal {

    .ant-btn.ant-btn-default,
    .ant-btn.ant-btn-primary {
        border-radius: 40px;
    }
}

.shadow,
.gird-box,
.filter-section,
.archive-card,
.role-switch,
.role-switch-homepage {
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 8px;
}

.step-parent {

    //letf side form
    .ant-picker {
        height: 40px;
        width: 100%;
        border-radius: 10px;
    }
    .textarea-field{
        .ant-input{
            height: auto !important;
        }
    }
    .asteriks-sign{
        .ant-form-item-label{
            &::before{
                display: inline-block;
                margin-right: 4px;
                color: #ff4d4f;
                font-size: 14px;
                font-family: SimSun, sans-serif;
                line-height: 1;
                content: "*";
            }
        }
    }
    .input-number{
        .ant-input-number{
            height: 40px;
            border-radius: 10px;
            width: 50%;
        }
        .ant-input-number-input{
            height: 40px;
        }
        .ant-input-number-handler-wrap{
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
    

    .border-bottom{
        height: 1.5px;
        background: #d9d9d9;
        margin-bottom: 24px;
    }
    .ant-input-group-addon:first-child, .ant-input-group-addon:first-child .ant-select .ant-select-selector, .ant-input-group > .ant-input:first-child, .ant-input-group > .ant-input:first-child .ant-select .ant-select-selector {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    .ant-switch {
        min-width: 44px;
        height: 35px;
        line-height: 35px;
        padding: 0 5px;
        background-image: none;
        background-color: #5f6062;

        .ant-switch-inner {
            font-size: 14px;
        }

        .ant-switch-handle {
            top: 11px;
            left: 11px;
        }
    }
    .ant-select-selector{
        border-radius: 10px !important;
    }
    .bottom-footer{

        margin-top: 10px;
        p{
            text-align: center;
            font-weight: bold;
        }
    }

    .ant-switch-checked {
        .ant-switch-handle {
            left: calc(100% - 25px) !important;
        }
    }

    .ant-switch-checked {
        background-color: #006ac3;
    }

    //right side
    .step-container {

        .step {
            padding: 15px 25px 15px 0px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            background-color: cream;
        }

        .v-stepper {
            position: relative;
            /*   visibility: visible; */
        }


        /* regular step */
        .step .circle {
            background-color: white;
            border: 3px solid #5f6062;
            border-radius: 100%;
            width: 40px;
            height: 40px;
            display: inline-block;
        }

        .step .line {
            top: 40px;
            left: 19px;
            /*   height: 120px; */
            height: 100%;

            position: absolute;
            border-left: 3px solid #5f6062;
        }

        .step .circle {
            background-color: #5f6062;
            border-color: #5f6062;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 16px;
            color: white;
            cursor: pointer;
        }

        .step.active .circle {
            visibility: visible;
            background-color: #006ac3;
            border-color: #006ac3;

        }

        .step.completed .line {
            border-left: 3px solid #006ac3;
        }

        // .step.active .circle {
        //     visibility: visible;
        //     border-color: rgb(6, 150, 215);
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     font-weight: bold;
        //     font-size: 16px;
        // }

        .step.empty .circle {
            visibility: hidden;
        }

        .step.empty .line {
            /*     visibility: hidden; */
            /*   height: 150%; */
            top: 0;
            height: 150%;
        }


        .step:last-child .line {
            border-left: 3px solid white;
            z-index: -1;
            /* behind the circle to completely hide */
        }

        .content {
            margin-right: 20px;
            width: 200px;
            text-align: right;
            display: inline-block;

            .title {
                font-weight: bold;
            }

            .sub-title {
                // font-size: ;
            }

            p {
                margin-bottom: 0px;
            }
        }
    }
    .prev-nextbtn{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .prev-next-arrow{
            font-weight: bold;
            height: 40px;
            width: 40px;
            &.ant-btn-primary:focus {
                border-color: #006ac3;
                background: #006ac3;
            }
            &.ant-btn-primary:hover {
                border-color: #006ac3;
                background: #006ac3;
            }
            // svg{
            //     font-size: 40px;
            //     color: #006ac3;
            //     cursor: pointer;
            // }
        }
    }
    .export-org{
        margin-bottom:30px;
    }
}

